
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Container, Skeleton, Stack, useDisclosure } from '@chakra-ui/react';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit';
import { useCustomer } from 'commercetools/hooks/use-customer';
import { useOrdersB2BAsAssociate } from 'commercetools/hooks/use-orders-b2b-as-associate';
import { Layout } from 'components/layouts/Layout';
import { OrdersTable } from 'components/modules/Order/OrdersTable';
import { RecentlyBoughtItems } from 'components/modules/Order/RecentlyBoughtItemsTable';
import { StartPageCard } from 'components/modules/StartPage/StartPageCard';
import { StartPageEquipmentCardList } from 'components/modules/StartPage/StartPageEquipmentCardList';
import { StartPageHeader } from 'components/modules/StartPage/StartPageHeader';
import { DO_NOT_DISPLAY_BUNDLES_WIZARD_KEY } from 'config/wizard';
import { arrayHasValue } from 'utils/arrayHasValue';
const ProductBundleWizardModal = dynamic(() => import('components/modules/ProductBundle/ProductBundleWizardModal').then((modal) => modal.ProductBundleWizardModal), { ssr: false });
const StartPage: NextPage = () => {
    const { t } = useTranslation('start');
    const [customer] = useCustomer();
    const wizardModal = useDisclosure();
    const businessUnit = useBusinessUnit();
    const bundlesEnabled = Boolean(businessUnit.current?.custom?.fields?.ctEnableBundles);
    const { data: orders, isFetching, isIdle } = useOrdersB2BAsAssociate({ page: 1, sort: 'createdAt desc', limit: 5 });
    const ordersHaveValues: boolean = arrayHasValue(orders?.results);
    useEffect(() => {
        const remindLater = sessionStorage.getItem(DO_NOT_DISPLAY_BUNDLES_WIZARD_KEY);
        if (!bundlesEnabled || remindLater || customer?.data?.custom?.fields?.isBundlesWizardCompleted) {
            return;
        }
        else {
            wizardModal.onOpen();
        }
    }, [bundlesEnabled, customer?.data?.custom?.fields?.isBundlesWizardCompleted, wizardModal]);
    return (<Layout customSubHeader={<StartPageHeader />}>
      <Container display="flex" flexDir="column" maxW="full" w="desktop" alignItems="center" mx={{ base: 0, md: 4 }} mt={12} p={0}>
        <Stack flex={1} flexBasis="min-content" h="min-content" w="full" maxW="desktop-content" direction="column" spacing={2}>
          <StartPageEquipmentCardList display={{ base: 'flex', md: 'none' }}/>
          <StartPageCard title={t('recent-orders')} link="my-pages/order-history" showButton={ordersHaveValues}>
            {isIdle || isFetching ? (<Skeleton h="240px" w="full"/>) : (<OrdersTable orders={orders} showSearchInput={false} type="business-unit"/>)}
          </StartPageCard>
          <StartPageCard title={t('common:recently-bought-items')} link="my-pages/order-history?defaultIndex=1" showButton={ordersHaveValues}>
            <RecentlyBoughtItems isStartPage={true}/>
          </StartPageCard>
        </Stack>
      </Container>

      <ProductBundleWizardModal {...wizardModal}/>
    </Layout>);
};
export default StartPage;

    async function __Next_Translate__getStaticProps__1927b45dad1__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index.page',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1927b45dad1__ as getStaticProps }
  